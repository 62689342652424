import React from 'react';
import { createRoute, Outlet, redirect } from '@tanstack/react-router';
import Navigation from './navigation';
import { rootRoute } from './root-route';

export const indexRoute = createRoute({
  getParentRoute: () => rootRoute,
  id: 'index',
  component: IndexPage,
  beforeLoad: ({ context, location }) => {
    const { isLoading, isAuthenticated } = context.auth;
    if (!isLoading && !isAuthenticated) {
      throw redirect({
        to: '/welcome',
        search: {
          returnTo: location.href,
        },
      });
    }
  },
});

export function IndexPage() {
  return (
    <div className="flex h-full w-full">
      <Navigation />
      <div className="flex-1 overflow-y-auto bg-slate-50">
        <Outlet />
      </div>
    </div>
  );
}

export default IndexPage;
