import { keepPreviousData, useQuery } from '@tanstack/react-query';

import { useHttpClient } from '@agyt/client/web/core/http';
import {
  SuccessApiResponse,
  TransactionCriteriaQuery,
  TransactionResponse,
} from '@agyt/shared/types';

export function useFindTransactions(
  criteria?: Partial<TransactionCriteriaQuery>,
) {
  const { httpClient } = useHttpClient();
  return useQuery({
    queryKey: ['transactions'],
    async queryFn() {
      const res = await httpClient.get('/transactions/find', {
        params: { filter: criteria },
      });
      return res?.data as SuccessApiResponse<TransactionResponse[]>;
    },
    staleTime: 30 * 1000,
    placeholderData: keepPreviousData,
    enabled: false,
  });
}

export function useGetTransaction({ id }: { id?: string }) {
  const { httpClient } = useHttpClient();
  return useQuery({
    queryKey: ['transctions', id],
    enabled: !!id,
    async queryFn() {
      const res = await httpClient.get(`/transactions/${id}`);
      return res?.data as SuccessApiResponse<TransactionResponse>;
    },
    staleTime: 30 * 1000,
    placeholderData: keepPreviousData,
  });
}
