import { cn } from '@agyt/client/shared/util/theme';
import { KeyValueObject } from '@agyt/shared/types';

export function DetailsCard({
  data,
  onClick,
}: {
  data: KeyValueObject<string>[];
  onClick?: () => void;
}) {
  return (
    <div className="mt-4 rounded-lg border border-slate-200 bg-white px-4 py-2">
      <ul>
        {data.map((row, index: number) => (
          <li
            className={cn('flex w-full items-center justify-between py-2', {
              'border-b border-slate-200': index !== data.length - 1,
            })}
            key={row.key}
          >
            <span className="text-sm font-medium leading-6 text-slate-400">
              {row.key}
            </span>
            <span
              className={cn('text-sm font-medium leading-6 text-slate-900', {
                'cursor-pointer': onClick,
                'hover:underline': onClick,
              })}
              onClick={onClick}
            >
              {row.value}
            </span>
          </li>
        ))}
      </ul>
    </div>
  );
}
