import { createContext, PropsWithChildren, useContext } from 'react';
import { SupportedCurrency } from '@agyt/shared/util/common';
import i18next from 'i18next';

type DayOfWeek = 0 | 1 | 2 | 3 | 4 | 5 | 6; // 0 - Sunday, 1 - Monday, ..., 6 - Saturday

interface UserProvider {
  locale: string;
  firstDayOfWeek: DayOfWeek; // 0 indexed day of the week 0-6
  primaryCurrency: SupportedCurrency;
}

export const UserContext = createContext<UserProvider | undefined>(undefined);

export function UserProvider({ children }: PropsWithChildren) {
  // this will be combination of browser locale, user settings and JWT info
  const language = navigator?.language ?? 'en';
  const locale = new Intl.Locale(language) as any;
  let firstDayOfWeek = 1;
  // Some browsers implement this as an accessor property, some as a method. FF has no support atm, so it will default to Monday.
  if (locale.weekInfo) {
    firstDayOfWeek = locale.weekInfo.firstDay % 7; // map from 1-7 for 7=Sunday, to 0-6 for 0=Sunday
  } else if (locale.getWeekInfo) {
    firstDayOfWeek = locale.getWeekInfo().firstDay % 7;
  }

  const user: UserProvider = {
    locale: language,
    firstDayOfWeek: firstDayOfWeek as DayOfWeek,
    primaryCurrency: 'EUR',
  };

  void i18next.changeLanguage(language);

  return <UserContext.Provider value={user}>{children}</UserContext.Provider>;
}

export const useUser = () => {
  const context = useContext(UserContext);

  if (!context) {
    throw new Error('useUser must be used inside the UserContextProvider');
  }

  return context;
};
