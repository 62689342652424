import {
  Badge,
  Button,
  CurrencyPicker,
  DataTable,
  DatePicker,
  Label,
  PageWrapper,
  Skeleton,
  TransactionStatusBadge,
  TransactionStatusPicker,
  TransactionTypePicker,
} from '@agyt/client/shared/ui/components';
import { useUser } from '@agyt/client/web/core/user';
import { useFindTransactions } from '@agyt/client/web/data-access/api';
import {
  TransactionCriteriaQuery,
  TransactionResponse,
} from '@agyt/shared/types';
import { LocalDate, Money } from '@agyt/shared/util/common';
import { Outlet, useNavigate } from '@tanstack/react-router';
import {
  ColumnDef,
  ColumnFiltersState,
  getCoreRowModel,
  getFacetedMinMaxValues,
  getFacetedRowModel,
  getFilteredRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

function EmptyListView() {
  const { t } = useTranslation('transactions');
  return (
    <section className="mt-5 flex h-[436px] w-full flex-col items-center justify-center rounded-lg border border-slate-200 bg-white p-4">
      <div className="flex max-w-min flex-col items-center justify-center">
        <h2 className="text-nowrap text-xl font-medium text-slate-900">
          {t('empty.title')}
        </h2>
      </div>
    </section>
  );
}

function TableSkeleton() {
  return (
    <div className="mt-5 flex flex-col gap-5">
      <Skeleton className="h-10 w-full rounded-full" />
      <Skeleton className="h-10 w-full rounded-full" />
      <Skeleton className="h-10 w-full rounded-full" />
      <Skeleton className="h-10 w-full rounded-full" />
      <Skeleton className="h-10 w-full rounded-full" />
      <Skeleton className="h-10 w-full rounded-full" />
    </div>
  );
}

export function TransactionsPage() {
  const { t } = useTranslation('transactions');
  const navigate = useNavigate({ from: '/transactions/$id' });
  const { locale } = useUser();
  const [shouldFetch, setShouldFetch] = useState(false);
  const [findCriteria, setFindCriteria] = useState<
    Partial<TransactionCriteriaQuery>
  >({});
  const {
    data: transactionsData,
    refetch: fetchTransactions,
    isFetching,
  } = useFindTransactions(findCriteria);

  const [globalFilter, setGlobalFilter] = useState('');
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);

  useEffect(() => {
    fetchTransactions();
  }, []);

  useEffect(() => {
    if (shouldFetch) {
      fetchTransactions();
      setShouldFetch(false);
    }
  }, [shouldFetch, fetchTransactions]);

  const columns: ColumnDef<TransactionResponse>[] = [
    {
      accessorKey: 'amount',
      header: t('table.columns.amount'),
      enableGlobalFilter: true,
      cell: ({ row: { original } }) => {
        const converted = Number(original.amount);
        const amount = new Money({
          amount: !isNaN(converted) ? converted : 0,
          currency: original.currency,
          locale,
        }).format();
        return <div>{amount}</div>;
      },
    },
    {
      accessorKey: 'currency',
      header: t('table.columns.currency'),
      enableGlobalFilter: true,
    },
    {
      accessorKey: 'createdAt',
      header: t('table.columns.date'),
      enableGlobalFilter: true,
      cell: ({ row: { original } }) => (
        <div>
          {new LocalDate({
            timestamp: original.createdAt,
            locale,
          }).format(DateTime.DATE_SHORT)}
        </div>
      ),
    },
    {
      accessorKey: 'id',
      header: t('table.columns.reference'),
      enableGlobalFilter: true,
    },
    {
      accessorKey: 'action',
      header: t('table.columns.transactionType'),
      enableGlobalFilter: true,
      cell: ({ row: { original } }) => {
        // @TODO fix, add all, i18n
        const actionMap: { [key: string]: string } = {
          funding: t('values.action.funding', { ns: 'transactions:details' }),
          conversion: t('values.action.conversion', {
            ns: 'transactions:details',
          }),
          payment: t('values.action.payment', { ns: 'transactions:details' }),
          payment_failure: t('values.action.paymentFailure', {
            ns: 'transactions:details',
          }),
          manual_intervention: t('values.action.manualInvervention', {
            ns: 'transactions:details',
          }),
          manual_transaction: t('values.action.manualTransaction', {
            ns: 'transactions:details',
          }),
          top_up_fee: t('values.action.topUpFee', {
            ns: 'transactions:details',
          }),
          transfer: t('values.action.transfer', { ns: 'transactions:details' }),
          conversion_deposit: t('values.action.conversionDeposit', {
            ns: 'transactions:details',
          }),
          deposit_refund: t('values.action.depostiRefund', {
            ns: 'transactions:details',
          }),
          payment_fee: t('values.action.paymentFee', {
            ns: 'transactions:details',
          }),
          payment_unrelease: t('values.action.paymentUnrelease', {
            ns: 'transactions:details',
          }),
          margin: t('values.action.margin', { ns: 'transactions:details' }),
        };
        return <div>{actionMap[original.action]}</div>;
      },
    },
    {
      accessorKey: 'status',
      header: t('table.columns.status'),
      enableGlobalFilter: true,
      cell: ({ row: { original } }) => (
        <TransactionStatusBadge status={original.status} />
      ),
    },
    // {
    //   header: 'Actions',
    //   cell: ({ row: { original } }) => (
    //     <Button
    //       disabled={original.relatedEntityType !== 'payment'}
    //       variant="subtle"
    //       onClick={(e) => {
    //         e.stopPropagation();
    //         navigate({
    //           to: '/transactions/$id',
    //           params: { id: original.relatedEntityId },
    //         });
    //       }}
    //     >
    //       <IconFileSearch />
    //     </Button>
    //   ),
    // },
  ];

  const table = useReactTable({
    data: transactionsData?.data || [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    onColumnFiltersChange: setColumnFilters,
    getFilteredRowModel: getFilteredRowModel(),
    onGlobalFilterChange: setGlobalFilter,
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
    state: {
      columnFilters,
      globalFilter,
    },
  });

  return (
    <PageWrapper>
      <header className="flex items-center justify-between">
        <div className="flex items-center gap-2">
          <h1 className="text-3xl font-medium">{t('title')}</h1>
          <span>
            {isFetching ? (
              <Skeleton className="h-6 w-8" />
            ) : (
              !!transactionsData?.data?.length && (
                <Badge className="h-6 rounded-sm py-0.5">
                  {transactionsData?.data.length}
                </Badge>
              )
            )}
          </span>
        </div>
      </header>
      <div className="mt-10">
        {/*<section className="mb-6 grid grid-cols-6 items-end gap-4">
           <div className="col-span-3">
            <DebouncedInput
              value={globalFilter ?? ''}
              onChange={(value) => setGlobalFilter(String(value))}
              placeholder="Search by IBAN, beneficiary, reference" // @TODO i18n
            >
              <IconSearch />
            </DebouncedInput>
          </div> 
        </section>*/}
        <section className="grid grid-cols-7 items-end gap-4">
          <div className="col-span-2 grid w-full items-center gap-1.5">
            <div className="flex w-full justify-between">
              <Label>{t('labels.date')}</Label>
              <Button
                className="h-5 w-10"
                variant="ghost"
                onClick={() => {
                  setFindCriteria((prevState) => ({
                    ...prevState,
                    createdAtFrom: undefined,
                    createdAtTo: undefined,
                  }));
                }}
              >
                {t('buttons.reset')}
              </Button>
            </div>
            <div className="flex w-full gap-2">
              <DatePicker
                placeholder={t('labels.from')}
                date={findCriteria?.createdAtFrom}
                onSelect={(date) =>
                  date &&
                  setFindCriteria((prevState) => ({
                    ...prevState,
                    createdAtFrom: new LocalDate({
                      timestamp: date,
                      locale,
                    }).toISO(),
                  }))
                }
              />
              <DatePicker
                placeholder={t('labels.to')}
                date={findCriteria?.createdAtTo}
                onSelect={(date) =>
                  date &&
                  setFindCriteria((prevState) => ({
                    ...prevState,
                    createdAtTo: new LocalDate({
                      timestamp: date,
                      locale,
                    }).toISO(),
                  }))
                }
              />
            </div>
          </div>
          <div className="grid w-full items-center gap-1.5">
            <Label>{t('labels.transactionType')}</Label>
            <TransactionTypePicker
              value={findCriteria?.action}
              onChange={(value) => {
                setFindCriteria((prevState) => ({
                  ...prevState,
                  action: value?.value,
                }));
              }}
            />
          </div>
          <div className="grid w-full items-center gap-1.5">
            <Label>{t('labels.status')}</Label>
            <TransactionStatusPicker
              value={findCriteria?.status}
              onChange={(value) => {
                setFindCriteria((prevState) => ({
                  ...prevState,
                  status: value?.value,
                }));
              }}
            />
          </div>
          <div className="grid w-full items-center gap-1.5">
            <Label>{t('labels.currency')}</Label>
            <CurrencyPicker
              value={findCriteria?.currency}
              onChange={(value) => {
                setFindCriteria((prevState) => ({
                  ...prevState,
                  currency: value?.value,
                }));
              }}
            />
          </div>
          <div className="col-span-2 flex gap-1 place-self-end">
            <Button onClick={() => fetchTransactions()}>
              {t('buttons.filter')}
            </Button>
            <Button
              variant="ghost"
              onClick={() => {
                setFindCriteria({});
                setShouldFetch(true);
              }}
            >
              {t('buttons.clear')}
            </Button>
          </div>
        </section>
        <section className="mt-7 flex w-full items-center justify-end">
          {isFetching ? (
            <Skeleton className="h-5 w-32" />
          ) : (
            <p className="text-sm text-slate-500">
              {t('labels.showing')} {transactionsData?.data?.length}&nbsp;
              {transactionsData?.data?.length !== 1 ? 'results' : 'result'}
            </p>
          )}
        </section>

        {isFetching ? (
          <TableSkeleton />
        ) : !transactionsData?.data?.length ? (
          <EmptyListView />
        ) : (
          <DataTable<TransactionResponse>
            table={table}
            onRowClick={({ id }) => {
              navigate({
                to: '/transactions/$id',
                params: { id },
              });
            }}
          />
        )}

        <Outlet />
      </div>
    </PageWrapper>
  );
}

export default TransactionsPage;
