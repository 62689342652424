import { useTranslation } from 'react-i18next';

export function TransactionStatusBadge({ status }: { status: string }) {
  const { t } = useTranslation('transactions:details');
  return (
    <>
      {status === 'completed' && (
        <div className="inline-block rounded-full bg-blue-100 px-2 py-1 text-center text-blue-800">
          {t('values.status.completed')}
        </div>
      )}
      {status === 'pending' && (
        <div className="inline-block rounded-full bg-lime-200 px-2 py-1 text-center text-lime-900">
          {t('values.status.pending')}
        </div>
      )}
      {status === 'deleted' && (
        <div className="inline-block rounded-full bg-red-100 px-2 py-1 text-center text-red-800">
          {t('values.status.failed')}
        </div>
      )}
    </>
  );
}

export default TransactionStatusBadge;
