import React from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { useTranslation } from 'react-i18next';

import {
  Button,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
  RadioGroup,
  RadioGroupItem,
  Separator,
  CurrencyPicker,
  CountryPicker,
  DatePicker,
  PaymentPurposeCodePicker,
  currencies,
} from '@agyt/client/shared/ui/components';
import { countries } from '@agyt/shared/util/common';
import { usePaymentDates } from '@agyt/client/web/data-access/api';

export const beneficiaryTypes = ['company', 'individual'] as const;

const beneficiaryTypeEnum = z.enum(beneficiaryTypes, {
  message: 'common.validation.required',
});

const beneficiaryDetailsForm = z.object({
  type: beneficiaryTypeEnum,
  name: z.string().min(1, {
    message: 'required',
  }),
  country: z.string().min(1, {
    message: 'required',
  }),
  bank_country: z.string().min(1, {
    message: 'required',
  }),
  currency: z.string().min(1, {
    message: 'required',
  }),
  bank_account_holder_name: z.string().min(1, {
    message: 'required',
  }),
});

export type BeneficiaryDetailsForm = z.infer<typeof beneficiaryDetailsForm>;

export interface BeneficiaryDetailsFormProps {
  submit: (data: BeneficiaryDetailsForm) => void;
  formData?: BeneficiaryDetailsForm;
}

export function BeneficiaryDetailsForm(props: BeneficiaryDetailsFormProps) {
  const { t } = useTranslation('beneficiaries:new');
  const { formData } = props;

  // TODO: Move where needed for day picker
  // const { data: paymentsData } = usePaymentDates({
  //   currency: 'EUR',
  // });

  // const disabledDays = () =>
  //   paymentsData &&
  //   paymentsData.data.invalidPaymentDates &&
  //   Object.keys(paymentsData.data.invalidPaymentDates).map((d) => new Date(d));

  const form = useForm<BeneficiaryDetailsForm>({
    resolver: zodResolver(beneficiaryDetailsForm),
    defaultValues: {
      type: formData?.type || beneficiaryTypeEnum.enum.company,
      name: formData?.name || '',
      country: formData?.country || '',
      bank_country: formData?.bank_country || '',
      currency: formData?.currency || '',
      bank_account_holder_name: formData?.bank_account_holder_name || '',
    },
  });

  function onSubmit(data: BeneficiaryDetailsForm) {
    props.submit(data);
  }

  return (
    <Form {...form}>
      <form className="mt-16" onSubmit={form.handleSubmit(onSubmit)}>
        <FormField
          control={form.control}
          name="type"
          render={({ field }) => (
            <FormItem>
              <FormLabel className="mb-2 text-sm font-medium text-slate-900">
                {t('beneficiaryDetails.beneficiaryType')}
              </FormLabel>
              <FormControl>
                <RadioGroup
                  onValueChange={field.onChange}
                  defaultValue={field.value}
                  className="flex gap-4"
                  {...field}
                >
                  <FormItem className="flex-1">
                    <FormLabel className="item-center flex h-10 flex-1 rounded-md border border-slate-200 bg-white p-3 text-slate-900 hover:cursor-pointer">
                      <FormControl>
                        <RadioGroupItem value="company"></RadioGroupItem>
                      </FormControl>
                      <span className="ml-2">
                        {t('beneficiaryDetails.company')}
                      </span>
                    </FormLabel>
                  </FormItem>
                  <FormItem className="flex-1">
                    <FormLabel className="item-center flex h-10 flex-1 rounded-md border border-slate-200 bg-white p-3 text-slate-900 hover:cursor-pointer">
                      <FormControl>
                        <RadioGroupItem value="individual"></RadioGroupItem>
                      </FormControl>
                      <span className="ml-2">
                        {t('beneficiaryDetails.individual')}
                      </span>
                    </FormLabel>
                  </FormItem>
                </RadioGroup>
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <Separator className="my-10" />

        <section>
          <h3 className="mb-6 text-xl font-medium text-slate-900">
            {t('beneficiaryDetails.beneficiaryInformation')}
          </h3>

          <FormField
            control={form.control}
            name="name"
            render={({ field }) => (
              <FormItem className="mb-4">
                <FormLabel className="mb-2 text-sm font-medium text-slate-900">
                  {t('beneficiaryDetails.labels.name')}
                </FormLabel>
                <FormControl>
                  <Input {...field}></Input>
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="country"
            render={({ field }) => (
              <FormItem className="mb-4">
                <FormLabel className="mb-2 text-sm font-medium text-slate-900">
                  {t('beneficiaryDetails.labels.country')}
                </FormLabel>
                <FormControl>
                  <CountryPicker
                    value={countries.find(
                      (country) => country.value === field.value,
                    )}
                    onChange={(country) => field.onChange(country?.value)}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          {/* TODO: Move where needed */}
          {/* {paymentsData && (
            <DatePicker
              disabled={disabledDays()}
              date={new Date()} // replace with state value
              onSelect={(date) => console.log(date)} // replace with state setter
            />
          )} */}

          {/* TODO: Move where needed */}
          {/* <FormField
            control={form.control}
            name="beneficiaryCountry" // change as needed
            render={({ field }) => (
              <FormItem className="mb-4">
                <FormLabel className="mb-2 text-sm font-medium text-slate-900">
                  {t('beneficiaryDetails.labels.purpose_code')}
                </FormLabel>
                <FormControl>
                  <PaymentPurposeCodePicker
                    country="CN" // make dynamic
                    currency="CNY" // make dynamic
                    entityType="individual"
                    onChange={(purposeCode) =>
                      field.onChange(purposeCode.value)
                    }
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          /> */}
        </section>

        <Separator className="my-10" />

        <section>
          <h3 className="mb-6 text-xl font-medium text-slate-900">
            {t('beneficiaryDetails.bankAccountInformation')}
          </h3>

          <FormField
            control={form.control}
            name="bank_country"
            render={({ field }) => (
              <FormItem className="mb-4">
                <FormLabel className="mb-2 text-sm font-medium text-slate-900">
                  {t('beneficiaryDetails.labels.bank_country')}
                </FormLabel>
                <FormControl>
                  <CountryPicker
                    value={countries.find(
                      (country) => country.value === field.value,
                    )}
                    onChange={(country) => field.onChange(country?.value)}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="currency"
            render={({ field }) => (
              <FormItem className="mb-4">
                <FormLabel className="mb-2 text-sm font-medium text-slate-900">
                  {t('beneficiaryDetails.labels.currency')}
                </FormLabel>
                <FormControl>
                  <CurrencyPicker
                    value={field.value}
                    onChange={(currency) => field.onChange(currency?.value)}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="bank_account_holder_name"
            render={({ field }) => (
              <FormItem>
                <FormLabel className="mb-2 text-sm font-medium text-slate-900">
                  {t('beneficiaryDetails.labels.bank_account_holder_name')}
                </FormLabel>
                <FormControl>
                  <Input {...field}></Input>
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </section>

        <div className="mt-16 flex gap-4">
          <Button type="submit" className="flex-1">
            {t('actions.next', { ns: 'common' })}
          </Button>
        </div>
      </form>
    </Form>
  );
}

export default BeneficiaryDetailsForm;
