import { CcTransactionAction } from '@agyt/shared/types';
import { Combobox, ComboboxItem } from './combobox';
import { useTranslation } from 'react-i18next';

const types = [
  { value: 'funding', label: 'Funding' },
  { value: 'conversion', label: 'Conversion' },
  { value: 'payment', label: 'Payment' },
  { value: 'payment_failure', label: 'Payment Failure' },
  { value: 'manual_intervention', label: 'Manual Intervention' },
  { value: 'manual_transaction', label: 'Manual Transaction' },
  { value: 'top_up_fee', label: 'Top-Up Fee' },
  { value: 'transfer', label: 'Transfer' },
  { value: 'conversion_deposit', label: 'Conversion Deposit' },
  { value: 'deposit_refund', label: 'Deposit Refund' },
  { value: 'payment_fee', label: 'Payment Fee' },
  { value: 'payment_unrelease', label: 'Payment Unrelease' },
  { value: 'margin', label: 'Margin' },
];

export interface TransactionType extends ComboboxItem {
  value: CcTransactionAction;
  label: string;
}

export interface TransactionTypePickerProps {
  value?: string;
  onChange: (value: TransactionType | undefined) => void;
}

export function TransactionTypePicker({
  value,
  onChange,
}: TransactionTypePickerProps) {
  const { t } = useTranslation('common');

  return (
    <Combobox<TransactionType>
      items={types}
      label={t('transactionType.label')}
      emptyLabel={t('transactionType.empty')}
      placeholder={t('transactionType.placeholder')}
      selectedItem={types.find((type) => type.value === value)}
      onSelect={onChange}
      disableSearch
    />
  );
}

export default TransactionTypePicker;
