import { keepPreviousData, useMutation, useQuery } from '@tanstack/react-query';

import { useHttpClient } from '@agyt/client/web/core/http';
import {
  SuccessApiResponse,
  PaymentResponse,
  PaymentTrackingResponse,
} from '@agyt/shared/types';
import { SupportedCurrency } from '@agyt/shared/util/common';

export function usePaymentDates({
  currency,
  startDate,
}: {
  currency?: string;
  startDate?: string;
}) {
  const { httpClient } = useHttpClient();
  return useQuery({
    queryKey: ['reference', startDate],
    enabled: !!currency,
    async queryFn() {
      try {
        const res = await httpClient.get('/payments/dates', {
          params: {
            currency,
            startDate,
          },
        });
        return res?.data;
      } catch (err: any) {
        return Promise.reject(err);
      }
    },
    placeholderData: keepPreviousData,
  });
}

export function useValidatePayment() {
  const { httpClient } = useHttpClient();
  return useMutation({
    // @TODO type
    mutationFn: async (data: any) => {
      try {
        const res = await httpClient.post('/payments/validate', data);
        return res.data as SuccessApiResponse<{
          fee: { currency: SupportedCurrency; amount: number };
        }>;
      } catch (err: any) {
        return Promise.reject(err);
      }
    },
    scope: {
      id: 'validatePayment',
    },
  });
}

export function useCreatePayment() {
  const { httpClient } = useHttpClient();
  return useMutation({
    // @TODO type
    mutationFn: async (data: any) => {
      try {
        const res = await httpClient.post('/payments/create', data);

        return res?.data as SuccessApiResponse<PaymentResponse>;
      } catch (err: any) {
        return Promise.reject(err);
      }
    },
    scope: {
      id: 'createPayment',
    },
  });
}

export function useGetPaymentTracking({ id }: { id?: string }) {
  const { httpClient } = useHttpClient();
  return useQuery({
    queryKey: ['paymentTracking', id],
    async queryFn() {
      const res = await httpClient.get(`/payments/${id}/tracking`);
      return res?.data as SuccessApiResponse<PaymentTrackingResponse>;
    },
    enabled: !!id,
    placeholderData: keepPreviousData,
  });
}

export function useGetPayment({ id }: { id?: string }) {
  const { httpClient } = useHttpClient();
  return useQuery({
    queryKey: ['payment', id],
    async queryFn() {
      const res = await httpClient.get(`/payments/${id}`);
      return res?.data as SuccessApiResponse<PaymentResponse>;
    },
    enabled: !!id,
    placeholderData: keepPreviousData,
  });
}
