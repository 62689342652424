import { useHttpClient } from '@agyt/client/web/core/http';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { Balance } from '@agyt/shared/types';

type PaginationInfo = {
  current_page: number;
  next_page: number;
  order: string;
  order_asc_desc: string;
  per_page: number;
  previous_page: number;
  total_entries: number;
  total_pages: number;
};

export function useBalances(showAll = false) {
  const { httpClient } = useHttpClient();
  return useQuery<{
    data: {
      balances: Balance[];
      overall: { amount: number; currency: string; timestamp: string };
    };
    pagination: PaginationInfo;
  }>({
    queryKey: ['balances:dashboard'],
    async queryFn() {
      const res = await httpClient.get(`/balances?showAll=${showAll}`);
      return res?.data;
    },
    placeholderData: keepPreviousData,
  });
}
