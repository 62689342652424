import { CcTransactionStatus } from '@agyt/shared/types';
import { Combobox, ComboboxItem } from './combobox';
import { useTranslation } from 'react-i18next';

const statuses = [
  { value: 'pending', label: 'Pending' },
  { value: 'completed', label: 'Completed' },
  { value: 'deleted', label: 'Failed' },
];

export interface TransactionStatus extends ComboboxItem {
  value: CcTransactionStatus;
  label: string;
}

export interface TransactionStatusPickerProps {
  value?: string;
  onChange: (value: TransactionStatus | undefined) => void;
}

export function TransactionStatusPicker({
  value,
  onChange,
}: TransactionStatusPickerProps) {
  const { t } = useTranslation('common');

  return (
    <Combobox<TransactionStatus>
      items={statuses}
      label={t('transactionStatus.label')}
      emptyLabel={t('transactionStatus.empty')}
      placeholder={t('transactionStatus.placeholder')}
      selectedItem={statuses.find((status) => status.value === value)}
      onSelect={onChange}
      disableSearch
    />
  );
}

export default TransactionStatusPicker;
