import { createRoute } from '@tanstack/react-router';
import { indexRoute } from '@agyt/client/web/feature/common';
import CreatePaymentPage from './create-payment-page';
import TransactionDetailsPage from './transaction-details-page';
import TransactionsPage from './transactions-page';
import AddTransactionPage from './add-transaction-page';

export const payRoot = createRoute({
  getParentRoute: () => indexRoute,
  validateSearch: (
    search: Record<string, unknown>,
  ): { beneficiaryId?: string } =>
    search.beneficiaryId
      ? {
          beneficiaryId: String(search.beneficiaryId),
        }
      : {},
  path: '/pay',
});

const fxRoot = createRoute({
  getParentRoute: () => indexRoute,
  path: '/fx',
});

const payIndexRoute = createRoute({
  getParentRoute: () => payRoot,
  path: '/',
  component: CreatePaymentPage,
});

const transactionRoot = createRoute({
  getParentRoute: () => indexRoute,
  path: '/transactions',
});

const transactionsIndexRoute = createRoute({
  getParentRoute: () => transactionRoot,
  path: '/',
  component: TransactionsPage,
});

const addTransactionRoute = createRoute({
  getParentRoute: () => transactionRoot,
  path: '/new',
  component: AddTransactionPage,
});

const transactionDetailsRoute = createRoute({
  getParentRoute: () => transactionRoot,
  path: '/$id',
  component: TransactionDetailsPage,
});

export const transactionsRoutes = transactionRoot.addChildren([
  transactionsIndexRoute,
  addTransactionRoute,
  transactionDetailsRoute,
]);

export const payRoutes = payRoot.addChildren([payIndexRoute]);
