import React from 'react';

export interface NavigationItemProps {
  title: string;
  icon: React.ReactNode;
}

export function NavigationItem({ icon, title }: NavigationItemProps) {
  return (
    <div className="flex w-full items-center gap-2 rounded-sm px-2 py-1.5 text-slate-500 hover:bg-slate-100 hover:text-slate-900 group-[.active]:bg-slate-100 group-[.active]:text-slate-900">
      {icon}
      <span className="text-sm font-medium">{title}</span>
    </div>
  );
}

export default NavigationItem;
