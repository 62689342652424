import { keepPreviousData, useMutation, useQuery } from '@tanstack/react-query';

import { useHttpClient } from '@agyt/client/web/core/http';
import {
  ConversionDatesCriteria,
  ConversionDatesResponse,
  ConversionResponse,
  CreateConversionPayloadBody,
  DetailedRatesCriteriaQuery,
  DetailedRatesFullResponse,
  SuccessApiResponse,
} from '@agyt/shared/types';

export function useGetFxRates() {
  const { httpClient } = useHttpClient();
  return useMutation({
    mutationFn: async (
      criteria: DetailedRatesCriteriaQuery,
    ): Promise<SuccessApiResponse<DetailedRatesFullResponse>> => {
      try {
        const res = await httpClient.get('/fx/rates', { params: criteria });
        return res?.data;
      } catch (err: any) {
        return Promise.reject(err);
      }
    },
    scope: {
      id: 'getFxRates',
    },
  });
}

export function useGetFxConversionDates() {
  const { httpClient } = useHttpClient();
  return useMutation({
    mutationFn: async (
      criteria: ConversionDatesCriteria,
    ): Promise<SuccessApiResponse<ConversionDatesResponse>> => {
      try {
        const res = await httpClient.get('/fx/dates', { params: criteria });
        return res?.data;
      } catch (err: any) {
        return Promise.reject(err);
      }
    },
    scope: {
      id: 'getFxDates',
    },
  });
}

export function useCreateConversion() {
  const { httpClient } = useHttpClient();
  return useMutation({
    mutationFn: async (
      data: CreateConversionPayloadBody,
    ): Promise<SuccessApiResponse<ConversionResponse>> => {
      try {
        const res = await httpClient.post('/fx', data);
        return res?.data;
      } catch (err: any) {
        return Promise.reject(err);
      }
    },
    scope: {
      id: 'createConversion',
    },
  });
}

export function useGetConversion({ id }: { id?: string }) {
  const { httpClient } = useHttpClient();
  return useQuery({
    queryKey: ['conversion', id],
    async queryFn() {
      const res = await httpClient.get(`/fx/${id}`);
      return res?.data as SuccessApiResponse<ConversionResponse>;
    },
    enabled: !!id,
    placeholderData: keepPreviousData,
  });
}
