import { Combobox, ComboboxItem } from './combobox';
import { useTranslation } from 'react-i18next';

export const currencies = [
  { label: '🇺🇸 USD', value: 'USD' },
  { label: '🇪🇺 EUR', value: 'EUR' },
  { label: '🇬🇧 GBP', value: 'GBP' },
  { label: '🇦🇪 AED', value: 'AED' },
  { label: '🇧🇭 BHD', value: 'BHD' },
  { label: '🇧🇬 BGN', value: 'BGN' },
  { label: '🇦🇺 AUD', value: 'AUD' },
  { label: '🇨🇭 CHF', value: 'CHF' },
  { label: '🇨🇳 CNY', value: 'CNY' },
  { label: '🇨🇦 CAD', value: 'CAD' },
  { label: '🇩🇰 DKK', value: 'DKK' },
  { label: '🇨🇿 CZK', value: 'CZK' },
  { label: '🇭🇺 HUF', value: 'HUF' },
  { label: '🇭🇰 HKD', value: 'HKD' },
  { label: '🇯🇵 JPY', value: 'JPY' },
  { label: '🇰🇪 KES', value: 'KES' },
  { label: '🇮🇱 ILS', value: 'ILS' },
  { label: '🇰🇼 KWD', value: 'KWD' },
  { label: '🇲🇽 MXN', value: 'MXN' },
  { label: '🇳🇴 NOK', value: 'NOK' },
  { label: '🇵🇱 PLN', value: 'PLN' },
  { label: '🇴🇲 OMR', value: 'OMR' },
  { label: '🇳🇿 NZD', value: 'NZD' },
  { label: '🇶🇦 QAR', value: 'QAR' },
  { label: '🇷🇴 RON', value: 'RON' },
  { label: '🇸🇦 SAR', value: 'SAR' },
  { label: '🇹🇭 THB', value: 'THB' },
  { label: '🇸🇪 SEK', value: 'SEK' },
  { label: '🇸🇬 SGD', value: 'SGD' },
  { label: '🇺🇬 UGX', value: 'UGX' },
  { label: '🇹🇷 TRY', value: 'TRY' },
  { label: '🇿🇦 ZAR', value: 'ZAR' },
  { label: '🇷🇺 RUB', value: 'RUB' },
  { label: '🇵🇭 PHP', value: 'PHP' },
  { label: '🇲🇾 MYR', value: 'MYR' },
];

export interface Currency extends ComboboxItem {
  value: string;
  label: string;
}

export interface CurrencyPickerProps {
  value?: string;
  onChange: (value: Currency | undefined) => void;
}

export function CurrencyPicker({ value, onChange }: CurrencyPickerProps) {
  const { t } = useTranslation('common');

  return (
    <Combobox<Currency>
      items={currencies}
      label={t('currency.label')}
      emptyLabel={t('currency.empty')}
      placeholder={t('currency.placeholder')}
      selectedItem={currencies.find((currency) => currency.value === value)}
      onSelect={onChange}
      resultClassName="max-h-[150px]"
    />
  );
}

export default CurrencyPicker;
