import { StrictMode } from 'react';
import * as ReactDOM from 'react-dom/client';
import { Auth0Provider } from '@auth0/auth0-react';
import { initReactI18next } from 'react-i18next';
import i18n from 'i18next';

import { HttpClientProvider } from '@agyt/client/web/core/http';
import App from './app/app';
import en from './assets/i18n/en.json';
import de from './assets/i18n/de.json';
import it from './assets/i18n/it.json';
import { UserProvider } from '@agyt/client/web/core/user';
import { Toaster } from '@agyt/client/shared/ui/components';

void i18n.use(initReactI18next).init({
  resources: {
    en,
    de,
    it,
  },
  lng: 'en',
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false,
  },
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
console.log(process.env);
const domain = process.env.NX_PUBLIC_AUTH0_DOMAIN || '';
const clientId = process.env.NX_PUBLIC_AUTH0_CLIENT_ID || '';
const audience = process.env.NX_PUBLIC_AUTH0_AUDIENCE || '';

root.render(
  <StrictMode>
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      authorizationParams={{
        redirect_uri: window.location.origin,
        audience,
      }}
    >
      <HttpClientProvider>
        <UserProvider>
          <App />
          <Toaster />
        </UserProvider>
      </HttpClientProvider>
    </Auth0Provider>
  </StrictMode>,
);
