import { zodResolver } from '@hookform/resolvers/zod';
import { Link } from '@tanstack/react-router';
import { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import { z } from 'zod';

import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
  AmountInput,
  Button,
  DatePicker,
  DetailsCard,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
  PageWrapper,
  Progress,
  RadioGroup,
  RadioGroupItem,
  Spinner,
} from '@agyt/client/shared/ui/components';
import { IconCheckCircle, IconPlusCircle } from '@agyt/client/shared/ui/icons';
import { useUser } from '@agyt/client/web/core/user';
import {
  useCreatePayment,
  useGetBeneficiaryAccounts,
  usePaymentDates,
  useValidatePayment,
} from '@agyt/client/web/data-access/api';
import { CreatePaymentPayloadBody } from '@agyt/shared/types';
import {
  getCountryNameFromCode,
  Money,
  stripEmptyStrings,
  SupportedCurrency,
} from '@agyt/shared/util/common';
import { BeneficiaryItem, BeneficiaryPicker } from './beneficiary-picker';
import { payRoot } from './router';

const enum CreatePaymentStep {
  'PAYMENT_DETAILS',
  'REVIEW',
  'SUCCESS',
}

const paymentDetailsForm = z.object({
  paymentType: z.string().min(1, {
    message: 'required',
  }),
  amount: z.string().min(1, {
    message: 'required',
  }),
  reference: z.string().min(1, {
    message: 'required',
  }),
  reason: z.string().min(1, {
    message: 'required',
  }),
  paymentDate: z.string().optional(),
});

type PaymentDetailsForm = z.infer<typeof paymentDetailsForm>;

const beneficiaryDetailsOrderMap = {
  name: 0,
  beneficiaryType: 1,
  iban: 2,
  accountNumber: 3,
  bic: 4,
  sortCode: 5,
  aba: 6,
  bsbCode: 7,
  institutionNumber: 8,
  bankCode: 9,
  branchCode: 10,
  clabe: 11,
  cnaps: 12,
  beneficiaryAddress: 13,
  beneficiaryCity: 14,
  beneficiaryCountry: 15,
};

const paymentDetailsOrderMap = {
  paymentType: 0,
  amount: 1,
  fee: 2,
  reference: 3,
  reason: 4,
  paymentDate: 5,
};

export function CreatePaymentPage() {
  const { t } = useTranslation('pay');
  const searchParams = payRoot.useSearch();
  const { locale } = useUser();
  const [beneficiary, setBeneficiary] = useState<BeneficiaryItem>();
  const [step, setStep] = useState<CreatePaymentStep>(
    CreatePaymentStep.PAYMENT_DETAILS,
  );
  const [progress, setProgress] = useState(33);
  const [fee, setFee] = useState<{
    currency: SupportedCurrency;
    amount: number;
  } | null>(null);
  const [validatedPayment, setValidatedPayment] =
    useState<Partial<CreatePaymentPayloadBody>>();
  const { data: accountsData, isFetching: isAccountDataFetching } =
    useGetBeneficiaryAccounts(beneficiary?.id, false, 3);

  const validatePayment = useValidatePayment();
  const createPayment = useCreatePayment();

  // In the first version we'll have 1 to 1 mapping between Beneficiaries and Account details, event though we have the support for 1:n.
  const currentAccount = useMemo(() => {
    if (!accountsData?.data?.length) {
      return null;
    }

    return accountsData.data[0];
  }, [accountsData]);

  const beneficiaryDetails = useMemo(() => {
    if (!currentAccount) {
      return null;
    }

    return Object.keys(beneficiaryDetailsOrderMap)
      .map((key, value) => {
        if (key === 'beneficiaryType') {
          return {
            key: t(`labels.${key}`, { ns: 'beneficiaries:details' }),
            value: t(`values.${currentAccount[key]}`, {
              ns: 'beneficiaries:details',
            }),
          };
        }
        if (key === 'beneficiaryAddress') {
          return {
            key: t(`labels.${key}`, { ns: 'beneficiaries:details' }),
            value: `${(currentAccount[key] as unknown as string[]).join(',')}`, // because CC returns a string[] even though a string is sent as input
          };
        }

        if (key === 'beneficiaryCountry') {
          return {
            key: t(`labels.${key}`, { ns: 'beneficiaries:details' }),
            value: getCountryNameFromCode(currentAccount[key]),
          };
        }

        return {
          key: t(`labels.${key}`, { ns: 'beneficiaries:details' }),
          value: (currentAccount as any)[key],
        };
      })
      .filter((o) => o.value);
  }, [currentAccount, t]);

  const paymentDetails = useMemo(() => {
    if (!validatedPayment) {
      return null;
    }

    return Object.keys(paymentDetailsOrderMap)
      .map((key, value) => {
        if (key === 'paymentType') {
          return {
            key: t(`labels.${key}`),
            value: t(`values.paymentType.${validatedPayment[key]}`),
          };
        }

        if (key === 'fee') {
          return {
            key: t(`labels.${key}`),
            value: fee?.amount
              ? new Money({
                  amount: fee?.amount,
                  currency: fee?.currency,
                  locale,
                }).format()
              : null,
          };
        }

        if (key === 'amount') {
          return {
            key: t(`labels.${key}`),
            value: new Money({
              amount: (validatedPayment.amount ?? 0) as number,
              currency: validatedPayment.currency ?? '',
              locale,
            }).format(),
          };
        }

        return {
          key: t(`labels.${key}`),
          value: (validatedPayment as any)[key],
        };
      })
      .filter((o) => o.value);
  }, [validatedPayment, t, fee, locale]);

  const { data: paymentsData } = usePaymentDates({
    currency: currentAccount?.currency,
  });

  const disabledDays = () =>
    paymentsData &&
    paymentsData.data.invalidPaymentDates &&
    Object.keys(paymentsData.data.invalidPaymentDates).map((d) => new Date(d));

  const defaultValues = useMemo(() => {
    const emptyValues = {
      paymentType: '',
      amount: '',
      reference: '',
      reason: '',
    };
    if (!currentAccount) {
      return emptyValues;
    }

    const paymentType =
      currentAccount.paymentTypes?.length > 1
        ? ''
        : currentAccount.paymentTypes[0];

    return {
      ...emptyValues,
      paymentType,
    };
  }, [currentAccount]);

  const form = useForm<PaymentDetailsForm>({
    resolver: zodResolver(paymentDetailsForm),
    values: defaultValues,
  });

  const hasMultiplePaymentTypes = useMemo(
    () =>
      currentAccount?.paymentTypes && currentAccount?.paymentTypes?.length > 1,
    [currentAccount],
  );

  async function onValidate(data: PaymentDetailsForm) {
    let payload = {
      ...data,
      beneficiaryId: currentAccount?.id,
      currency: currentAccount?.currency,
    };

    payload = stripEmptyStrings(payload);

    try {
      const feeData = await validatePayment.mutateAsync(payload);
      setFee(feeData.data.fee);

      const uniqueId = uuidv4();
      const validatedPayload = { ...payload, uniqueRequestId: uniqueId };
      setValidatedPayment(validatedPayload);
      setStep(CreatePaymentStep.REVIEW);
      setProgress(66);
    } catch (error) {
      // TODO handle errors
      console.log(error);
    }
  }

  async function confirmPayment() {
    try {
      await createPayment.mutateAsync(validatedPayment);
      setStep(CreatePaymentStep.SUCCESS);
    } catch (error) {
      // @TODO handle errors
      console.error('ERROR:', error);
    }
  }

  return (
    <PageWrapper className="flex flex-col items-center">
      <div className="flex h-full w-[555px] flex-col">
        {step !== CreatePaymentStep.SUCCESS && (
          <header className="flex flex-col items-center justify-between">
            <div className="relative w-full">
              <Progress value={progress} />
            </div>
          </header>
        )}
        <div className="h-full">
          {step === CreatePaymentStep.PAYMENT_DETAILS && (
            <section>
              <div className="mb-16 mt-14 w-full">
                <h2 className="text-3xl font-medium">{t('details.title')}</h2>
              </div>

              <Form {...form}>
                <form
                  className="mt-16"
                  onSubmit={form.handleSubmit(onValidate)}
                >
                  <div className="flex items-center gap-4">
                    <div className="mb-4 w-2/3">
                      <FormLabel className="mb-2 text-sm font-medium text-slate-900">
                        {t('labels.beneficiary')}
                      </FormLabel>
                      <BeneficiaryPicker
                        prefillId={searchParams.beneficiaryId}
                        value={beneficiary}
                        onChange={(v) => {
                          form.reset();
                          setBeneficiary(v);

                          if (searchParams.beneficiaryId) {
                            window.history.replaceState({}, '', '/pay');
                          }
                        }}
                      />
                    </div>
                    <Link to="/beneficiaries/new" search={{ returnTo: '/pay' }}>
                      <Button
                        variant="ghost"
                        className="text-blue-500"
                        type="button"
                      >
                        <IconPlusCircle />
                        <span className="ml-2">{t('addBeneficiary')}</span>
                      </Button>
                    </Link>
                  </div>

                  {isAccountDataFetching && (
                    <div className="flex justify-center">
                      <Spinner />
                    </div>
                  )}

                  {beneficiary && !currentAccount && !isAccountDataFetching && (
                    <>{t('errors.no_account_data')}</>
                  )}

                  {currentAccount && !isAccountDataFetching && (
                    <>
                      {hasMultiplePaymentTypes && (
                        <FormField
                          control={form.control}
                          name="paymentType"
                          render={({ field }) => (
                            <FormItem className="mb-4">
                              <FormControl>
                                <RadioGroup
                                  onValueChange={field.onChange}
                                  defaultValue={field.value}
                                  className="flex gap-4"
                                  {...field}
                                >
                                  <FormItem className="flex-1">
                                    <FormLabel className="item-center flex h-10 flex-1 rounded-md border border-slate-200 bg-white p-3 text-slate-900 hover:cursor-pointer">
                                      <FormControl>
                                        <RadioGroupItem value="local"></RadioGroupItem>
                                      </FormControl>
                                      <span className="ml-2">
                                        {t('values.paymentType.local')}
                                      </span>
                                    </FormLabel>
                                  </FormItem>
                                  <FormItem className="flex-1">
                                    <FormLabel className="item-center flex h-10 flex-1 rounded-md border border-slate-200 bg-white p-3 text-slate-900 hover:cursor-pointer">
                                      <FormControl>
                                        <RadioGroupItem value="swift"></RadioGroupItem>
                                      </FormControl>
                                      <span className="ml-2">
                                        {t('values.paymentType.swift')}
                                      </span>
                                    </FormLabel>
                                  </FormItem>
                                </RadioGroup>
                              </FormControl>
                              <FormMessage />
                            </FormItem>
                          )}
                        />
                      )}

                      <FormField
                        control={form.control}
                        name="amount"
                        render={({ field }) => (
                          <FormItem className="mb-4">
                            <FormLabel className="mb-2 text-sm font-medium text-slate-900">
                              {t('labels.amount')}
                            </FormLabel>
                            <FormControl>
                              <AmountInput
                                {...field}
                                suffix={currentAccount.currency}
                              />
                            </FormControl>
                            <FormMessage />
                          </FormItem>
                        )}
                      />

                      <FormField
                        control={form.control}
                        name="reference"
                        render={({ field }) => (
                          <FormItem className="mb-4">
                            <FormLabel className="mb-2 text-sm font-medium text-slate-900">
                              {t('labels.reference')}
                            </FormLabel>
                            <FormControl>
                              <Input {...field}></Input>
                            </FormControl>
                            <FormMessage />
                          </FormItem>
                        )}
                      />

                      <FormField
                        control={form.control}
                        name="reason"
                        render={({ field }) => (
                          <FormItem className="mb-4">
                            <FormLabel className="mb-2 text-sm font-medium text-slate-900">
                              {t('labels.reason')}
                            </FormLabel>
                            <FormControl>
                              <Input {...field}></Input>
                            </FormControl>
                            <FormMessage />
                          </FormItem>
                        )}
                      />

                      <FormField
                        control={form.control}
                        name="paymentDate"
                        render={({ field }) => (
                          <FormItem className="mb-4">
                            <FormLabel className="mb-2 text-sm font-medium text-slate-900">
                              {t('labels.date')}
                            </FormLabel>
                            <FormControl>
                              <div>
                                <DatePicker
                                  className="w-full"
                                  disabled={disabledDays()}
                                  date={field.value}
                                  onSelect={field.onChange}
                                />
                              </div>
                            </FormControl>
                            <FormMessage />
                          </FormItem>
                        )}
                      />

                      <Accordion type="single" collapsible>
                        <AccordionItem value="item-1">
                          <AccordionTrigger className="border-0 bg-transparent px-1 text-sm font-medium leading-6 text-blue-500">
                            {t('details.beneficiaryDetailsSectionTrigger')}
                          </AccordionTrigger>
                          <AccordionContent>
                            {beneficiaryDetails && (
                              <DetailsCard data={beneficiaryDetails} />
                            )}
                          </AccordionContent>
                        </AccordionItem>
                      </Accordion>

                      <div className="mt-16 flex gap-4">
                        <Button type="submit" className="flex-1">
                          {t('actions.next', { ns: 'common' })}
                        </Button>
                      </div>
                    </>
                  )}
                </form>
              </Form>
            </section>
          )}
          {step === CreatePaymentStep.REVIEW && (
            <section>
              <div className="mb-16 mt-14 w-full">
                <h2 className="text-3xl font-medium">{t('review.title')}</h2>
              </div>
              <div>
                <h3 className="mb-4 text-xl font-medium text-slate-900">
                  {t('review.beneficarySectionTitle')}
                </h3>
                <div>
                  {beneficiaryDetails && (
                    <DetailsCard data={beneficiaryDetails} />
                  )}
                </div>
              </div>
              <div className="mt-10">
                <h3 className="mb-4 text-xl font-medium text-slate-900">
                  {t('review.paymentSectionTitle')}
                </h3>
                <div>
                  {paymentDetails && <DetailsCard data={paymentDetails} />}
                </div>
              </div>
              <div className="mt-16 flex gap-4">
                <Button
                  className="flex-1"
                  variant={'subtleDark'}
                  onClick={() => setStep(CreatePaymentStep.PAYMENT_DETAILS)}
                >
                  {t('actions.back', { ns: 'common' })}
                </Button>
                <Button className="flex-1" onClick={confirmPayment}>
                  {t('actions.createPayment')}
                </Button>
              </div>
            </section>
          )}
          {step === CreatePaymentStep.SUCCESS && (
            <section className="mt-14 flex flex-col items-center">
              <IconCheckCircle />
              <h2 className="my-9 text-xl font-medium text-slate-900">
                {t('success.title')}
              </h2>
              <Link to={'/transactions'}>
                <Button>{t('actions.close', { ns: 'common' })}</Button>
              </Link>
              {/* <Progress value={50} className="mt-6 h-1 w-24" /> */}
            </section>
          )}
        </div>
      </div>
    </PageWrapper>
  );
}

export default CreatePaymentPage;
