import { useTranslation } from 'react-i18next';

import { Combobox, ComboboxItem } from './combobox';
import { countries } from '@agyt/shared/util/common';

export interface Country extends ComboboxItem {
  value: string;
  label: string;
}

export interface CountryPickerProps {
  value?: Country;
  onChange: (value: Country | undefined) => void;
}

export function CountryPicker({ onChange, value }: CountryPickerProps) {
  const { t } = useTranslation('common');

  return (
    <Combobox<Country>
      items={countries}
      label={t('country.label')}
      emptyLabel={t('country.empty')}
      placeholder={t('country.placeholder')}
      selectedItem={value}
      onSelect={onChange}
      searchByLabel
    />
  );
}

export default CountryPicker;
