import { useTranslation } from 'react-i18next';
import { Button } from './button';

export function AccountCard({
  name,
  balance,
  children,
  onPay,
  onExchange,
}: {
  name: string;
  balance: string;
  children: React.ReactNode;
  onPay: () => void;
  onExchange: () => void;
}) {
  const { t } = useTranslation('common');
  return (
    <div className="flex h-[179px] min-w-[363px] rounded-lg border border-slate-200 bg-white p-4">
      <div className="flex h-full flex-col">
        <div className="mb-4 text-base leading-7 text-slate-500">{name}</div>
        <div className="text-xl font-medium text-slate-900">{balance}</div>
        <div className="flex flex-1 items-end gap-3">
          <Button variant="subtle" size="sm" onClick={onPay}>
            {t('actions.pay')}
          </Button>
          <Button variant="subtle" size="sm" onClick={onExchange}>
            {t('actions.exchange')}
          </Button>
        </div>
      </div>
      <div className="flex flex-1 items-start justify-end">{children}</div>
    </div>
  );
}

export default AccountCard;
