import {
  Button,
  Checkbox,
  DebouncedInput,
  PageWrapper,
  Skeleton,
  // Progress
} from '@agyt/client/shared/ui/components';
import { IconCheckCircle, IconSearch } from '@agyt/client/shared/ui/icons';
import { cn } from '@agyt/client/shared/util/theme';
import {
  useCreateAccountCurrencies,
  useGetAvailableCurrencies,
} from '@agyt/client/web/data-access/api';
import { SupportedCurrency } from '@agyt/shared/util/common';
import { Link, useNavigate } from '@tanstack/react-router';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const LoadingSkeleton = (count: number) => (
  <>
    {[...Array(count)].map((_, i) => (
      <Skeleton key={i} className="h-10" />
    ))}
  </>
);

export function AddAccountCurrencyPage() {
  const { t } = useTranslation('accountCurrencies:new');
  const navigate = useNavigate({ from: '/account-currencies/new' });
  const { data: currencies, isLoading } = useGetAvailableCurrencies();
  const { mutate, isPending, isSuccess } = useCreateAccountCurrencies();

  const [searchValue, setSearchValue] = useState('');
  const [selectedCurrency, setSelectedCurrency] = useState<SupportedCurrency>();
  const [addedCurrencies, setAddedCurrencies] = useState(false);
  // const [redirectProgress, setRedirectProgress] = useState(0);

  // useEffect(() => {
  //   if (addedCurrencies) {
  //     const interval = setInterval(() => {
  //       setRedirectProgress((prevProgress) => {
  //         if (prevProgress >= 100) {
  //           clearInterval(interval);
  //           navigate({
  //             to: '/account-currencies',
  //           });
  //           return 100;
  //         }
  //         return prevProgress + 1;
  //       });
  //     }, 50);

  //     return () => clearInterval(interval);
  //   }
  // }, [navigate, addedCurrencies]);

  useEffect(() => {
    if (isSuccess) {
      setAddedCurrencies(true);
    }
  }, [isSuccess]);

  const handleSearchChange = (value: string) => {
    setSearchValue(value);
  };

  const filteredCurrencies = currencies?.data.filter(({ code, name }) =>
    `${code} ${name}`.toLowerCase().includes(searchValue.toLowerCase()),
  );

  if (addedCurrencies) {
    return (
      <PageWrapper className="flex h-full w-full items-center justify-center">
        <div className="flex w-[555px] flex-col">
          <section className="flex flex-col items-center">
            <IconCheckCircle />
            <h2 className="mt-8 text-xl font-medium text-slate-900">
              {selectedCurrency &&
                t('success.title', {
                  currency: selectedCurrency,
                })}
            </h2>
            <Link to="/account-currencies">
              <Button className="mt-9">
                {t('actions.close', { ns: 'common' })}
              </Button>
            </Link>
            {/* <Progress value={redirectProgress} className="mt-6 h-1 w-24" /> */}
          </section>
        </div>
      </PageWrapper>
    );
  }

  return (
    <PageWrapper className="flex flex-col items-center">
      <div className="flex w-[555px] flex-col gap-6">
        <section>
          <h1 className="my-14 text-3xl font-medium text-slate-900">
            {t('header')}
          </h1>
          <div className="mb-2 text-xl font-normal text-slate-900">
            {t('subheader')}
          </div>
          <div className="text-base font-normal text-slate-400">
            {t('description')}
          </div>
        </section>

        <DebouncedInput
          value={searchValue}
          onChange={(value) => handleSearchChange(String(value))}
          debounce={100}
          placeholder="Search"
        >
          <IconSearch className="text-slate-500" />
        </DebouncedInput>

        <section>
          {!isLoading ? (
            <div className="flex h-[450px] flex-col gap-2 overflow-y-auto">
              {filteredCurrencies?.map(({ code, name }) => (
                <div
                  key={code}
                  className={cn(
                    'flex w-full cursor-pointer items-center gap-2 rounded-lg bg-white p-3',
                    {
                      'border border-slate-200': selectedCurrency !== code,
                      'border border-slate-900': selectedCurrency === code,
                    },
                  )}
                  onClick={() => setSelectedCurrency(code)}
                >
                  {code} - {name}
                </div>
              ))}
            </div>
          ) : (
            <div className="flex h-[360px] flex-col gap-2">
              {LoadingSkeleton(7)}
            </div>
          )}
        </section>

        <section className="flex gap-4">
          <Button
            variant="subtleDark"
            className="flex-1"
            onClick={() =>
              navigate({
                to: '/account-currencies',
              })
            }
          >
            {t('actions.cancel', { ns: 'common' })}
          </Button>
          <Button
            className="flex-1"
            onClick={() => {
              if (selectedCurrency) {
                mutate(selectedCurrency);
              }
            }}
            disabled={!selectedCurrency || isPending}
          >
            {selectedCurrency
              ? t('actions.addCurrencies', {
                  currency: selectedCurrency,
                })
              : t('actions.selectCurrency')}
          </Button>
        </section>
      </div>
    </PageWrapper>
  );
}

export default AddAccountCurrencyPage;
