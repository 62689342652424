import { Link, useNavigate } from '@tanstack/react-router';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Button,
  PageWrapper,
  Progress,
  toast,
} from '@agyt/client/shared/ui/components';
import { IconCheckCircle } from '@agyt/client/shared/ui/icons';
import {
  BeneficiaryRequirements,
  useBeneficiaryRequirements,
  useCreateBeneficiary,
  useValidateBeneficiary,
} from '@agyt/client/web/data-access/api';
import { ApiFieldError, BeneficiaryResponse } from '@agyt/shared/types';
import { SupportedCurrency } from '@agyt/shared/util/common';
import AddBeneficiaryReview from './add-beneficiary-review';
import BankAccountDetailsForm from './bank-account-details-form';
import { addBeneficiaryRoute } from './beneficiaries-page';
import BeneficiaryDetailsForm from './beneficiary-details-form';

const enum AddBeneficiaryStep {
  'BENEFICIARY_DETAILS',
  'BANK_ACCOUNT_DETAILS',
  'REVIEW',
  'SUCCESS',
}

function AccountDetailsSection(props: {
  formData: any;
  showSwift: boolean;
  setShowSwift: (show: boolean) => void;
  isLoading: boolean;
  beneficiaryDetails?: BeneficiaryDetailsForm;
  beneficiaryRequirements?: BeneficiaryRequirements[];
  onBankAccountDetailsSubmit: (data: any) => void;
  onBankAccountDetailsBack: () => void;
  submit: (data: any) => void;
  errors?: ApiFieldError[];
}) {
  const { t } = useTranslation('beneficiaries:new');

  if (
    props.isLoading ||
    !props.beneficiaryDetails ||
    !props.beneficiaryRequirements
  ) {
    return <div>Loading...</div>;
  }

  const filteredRequirements = props.beneficiaryRequirements?.filter(
    (r: BeneficiaryRequirements) =>
      r.beneficiary_entity_type === props.beneficiaryDetails?.type,
  );

  return (
    <section>
      <h2 className="text-3xl font-medium text-slate-900">
        {t('bankAccountDetails.title')}
      </h2>
      <BankAccountDetailsForm
        formData={props.formData}
        showSwift={props.showSwift}
        setShowSwift={props.setShowSwift}
        beneficiaryRequirements={filteredRequirements}
        submit={props.onBankAccountDetailsSubmit}
        back={props.onBankAccountDetailsBack}
        errors={props.errors}
      />
    </section>
  );
}

export function AddBeneficiaryPage() {
  const { t } = useTranslation('beneficiaries:new');
  const navigate = useNavigate({ from: '/beneficiaries/new' });
  const searchParams = addBeneficiaryRoute.useSearch();

  const [step, setStep] = useState<AddBeneficiaryStep>(
    AddBeneficiaryStep.BENEFICIARY_DETAILS,
  );
  const [beneficiaryDetails, setBeneficiaryDetails] = useState<
    BeneficiaryDetailsForm | undefined
  >();
  const [accountDetails, setAccountDetails] = useState<any>([]);
  const [validateBeneficiaryErrors, setValidateBeneficiaryErrors] = useState<
    ApiFieldError[]
  >([]);
  const [validatedBeneficiary, setValidatedBeneficiary] =
    useState<BeneficiaryResponse>();
  const [showSwift, setShowSwift] = useState(false);
  const [progress, setProgress] = useState<number>(33);
  const [redirectProgress, setRedirectProgress] = useState<number>(0);

  const {
    data: beneficiaryRequirementsData,
    isLoading: isBeneficiaryRequirementsLoading,
  } = useBeneficiaryRequirements({
    currency: beneficiaryDetails?.currency as SupportedCurrency,
    beneficiaryCountry: beneficiaryDetails?.country ?? '',
    bankAccountCountry: beneficiaryDetails?.bank_country ?? '',
  });
  const validateBeneficiary = useValidateBeneficiary();
  const createBeneficiary = useCreateBeneficiary();

  useEffect(() => {
    if (step === AddBeneficiaryStep.SUCCESS) {
      const interval = setInterval(() => {
        setRedirectProgress((prevProgress) => {
          if (prevProgress >= 100) {
            clearInterval(interval);
            const returnTo = searchParams.returnTo ?? '/beneficiaries';
            navigate({
              to: returnTo,
            });
            return 100;
          }
          return prevProgress + 1;
        });
      }, 50);

      return () => clearInterval(interval);
    }
  }, [step, navigate, searchParams]);

  async function onBeneficiaryDetailsSubmit(data: any) {
    setBeneficiaryDetails(data);
    setStep(AddBeneficiaryStep.BANK_ACCOUNT_DETAILS);
    setProgress(66);
  }

  async function onBankAccountDetailsSubmit(data: any) {
    setAccountDetails(data);

    try {
      const validatedBeneficiary = await validateBeneficiary.mutateAsync({
        beneficiaryDetails,
        accountDetails: data,
      });
      setValidateBeneficiaryErrors([]);
      setValidatedBeneficiary(validatedBeneficiary.data);
      setStep(AddBeneficiaryStep.REVIEW);
      setProgress(100);
    } catch (error) {
      const errors = (error as any).response.data.errors;
      setValidateBeneficiaryErrors(errors);
    }
  }

  async function onBeneficiaryReviewSubmit() {
    try {
      await createBeneficiary.mutateAsync({
        beneficiaryDetails,
        accountDetails,
      });
      setStep(AddBeneficiaryStep.SUCCESS);
    } catch (error) {
      toast.error(t('errors.create_failed'));
    }
  }

  function onBankAccountDetailsBack() {
    setStep(AddBeneficiaryStep.BENEFICIARY_DETAILS);
    setProgress(33);
  }

  function onBeneficiaryReviewBack() {
    setStep(AddBeneficiaryStep.BANK_ACCOUNT_DETAILS);
    setProgress(66);
  }

  function renderStep() {
    switch (step) {
      case AddBeneficiaryStep.BANK_ACCOUNT_DETAILS:
        return (
          <AccountDetailsSection
            showSwift={showSwift}
            setShowSwift={setShowSwift}
            formData={accountDetails}
            isLoading={isBeneficiaryRequirementsLoading}
            beneficiaryDetails={beneficiaryDetails}
            beneficiaryRequirements={beneficiaryRequirementsData?.data}
            onBankAccountDetailsSubmit={onBankAccountDetailsSubmit}
            onBankAccountDetailsBack={onBankAccountDetailsBack}
            submit={onBankAccountDetailsSubmit}
            errors={validateBeneficiaryErrors}
          />
        );
      case AddBeneficiaryStep.REVIEW:
        return (
          <section>
            <h2 className="text-3xl font-medium text-slate-900">
              {t('beneficiaryDetails.title')}
            </h2>
            {validatedBeneficiary && (
              <AddBeneficiaryReview
                beneficiary={{
                  ...validatedBeneficiary,
                  name: beneficiaryDetails?.name ?? '',
                  bankAccountHolderName:
                    beneficiaryDetails?.bank_account_holder_name ?? '',
                }}
                back={onBeneficiaryReviewBack}
                submit={onBeneficiaryReviewSubmit}
              />
            )}
          </section>
        );
      case AddBeneficiaryStep.SUCCESS:
        return (
          <section className="flex flex-col items-center">
            <IconCheckCircle />
            <h2 className="mt-8 text-xl font-medium text-slate-900">
              {t('success.title')}
            </h2>
            <div className="mt-7 text-base leading-7 text-slate-500">
              <span className="font-semibold">{beneficiaryDetails?.name}</span>
              &nbsp;
              <span className="">{t('success.subtitle')}</span>
            </div>
            <Link
              to={
                searchParams.returnTo ? searchParams.returnTo : '/beneficiaries'
              }
            >
              <Button className="mt-9">
                {t('actions.finish', { ns: 'common' })}
              </Button>
            </Link>
            <Progress value={redirectProgress} className="mt-6 h-1 w-24" />
          </section>
        );
      case AddBeneficiaryStep.BENEFICIARY_DETAILS:
      default:
        return (
          <section>
            <h2 className="text-3xl font-medium text-slate-900">
              {t('beneficiaryDetails.title')}
            </h2>
            <BeneficiaryDetailsForm
              submit={onBeneficiaryDetailsSubmit}
              formData={beneficiaryDetails}
            />
          </section>
        );
    }
  }

  return (
    <PageWrapper className="flex flex-col items-center">
      <div className="flex w-[555px] flex-col">
        {step !== AddBeneficiaryStep.SUCCESS && (
          <div className="relative w-full">
            <Progress value={progress} />
          </div>
        )}
        <div className="mt-14 w-full">{renderStep()}</div>
      </div>
    </PageWrapper>
  );
}
