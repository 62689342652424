import { createRouter } from '@tanstack/react-router';
import {
  indexRoute,
  rootRoute,
  welcomeRoute,
} from '@agyt/client/web/feature/common';
import { dashboardRoute } from '@agyt/client/web/feature/dashboard';
import { beneficiariesRoutes } from '@agyt/client/web/feature/beneficiaries';
import {
  payRoutes,
  transactionsRoutes,
} from '@agyt/client/web/feature/transactions';
import { accountCurrenciesRoutes } from '@agyt/client/web/feature/account-currencies';
import { currencyExchangeRoutes } from '@agyt/client/web/feature/currency-exchange';

const routeTree = rootRoute.addChildren([
  indexRoute.addChildren([
    dashboardRoute,
    beneficiariesRoutes,
    transactionsRoutes,
    payRoutes,
    accountCurrenciesRoutes,
    currencyExchangeRoutes,
  ]), // This is purposefully composed here and not in the indexRoute definition to provide a clear global route tree
  welcomeRoute,
]);

export const router = createRouter({
  routeTree,
  defaultPreload: 'intent',
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  context: { auth: undefined! },
});

declare module '@tanstack/react-router' {
  interface Register {
    router: typeof router;
  }
}
