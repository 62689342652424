import { useNavigate, useParams } from '@tanstack/react-router';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DateTime } from 'luxon';

import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
  Button,
  DetailsCard,
  PageWrapper,
  Skeleton,
  TransactionStatusBadge,
} from '@agyt/client/shared/ui/components';
import { useUser } from '@agyt/client/web/core/user';
import {
  useGetBeneficiaryAccounts,
  useGetConversion,
  useGetFxRates,
  useGetPayment,
  useGetPaymentTracking,
  useGetTransaction,
} from '@agyt/client/web/data-access/api';
import {
  getCountryNameFromCode,
  LocalDate,
  Money,
} from '@agyt/shared/util/common';

const transactionDetailOrderMap = {
  action: 0,
  amount: 1,
  reason: 2,
  status: 3,
  createdAt: 4,
  settledAt: 5,
  completedAt: 6,
};

const beneficiaryDetailsOrderMap = {
  name: 0,
  beneficiaryType: 1,
  iban: 2,
  bic: 3,
  beneficiaryAddress: 4,
  beneficiaryCity: 5,
  beneficiaryCountry: 6,
};

const conversionDetailsOrderMap = {
  buyCurrency: 0,
  sellCurrency: 1,
  status: 2,
  fixedSide: 3,
  clientRate: 4,
  createdAt: 5,
  settlementDate: 6,
  conversionDate: 7,
};

export function TransactionDetailsPage() {
  const navigate = useNavigate({ from: '/transactions/$id' });
  const { id } = useParams({ strict: false });
  const { t } = useTranslation('transactions:details');
  const { locale } = useUser();
  const [paymentId, setPaymentId] = useState<string | undefined>();
  const [conversionId, setConversionId] = useState<string | undefined>();
  const [beneficiaryId, setBeneficiaryId] = useState<string | undefined>();
  const [inverse, setInverse] = useState<any>();
  const { data: transactionData, isFetching } = useGetTransaction({
    id,
  });
  const { mutateAsync: fetchFxRates } = useGetFxRates();

  useEffect(() => {
    if (transactionData?.data.relatedEntityType === 'payment') {
      setPaymentId(transactionData?.data.relatedEntityId);
    }
    if (transactionData?.data.relatedEntityType === 'conversion') {
      setConversionId(transactionData?.data.relatedEntityId);
    }
  }, [transactionData]);

  const { data: paymentTrackingData } = useGetPaymentTracking({
    id: paymentId,
  });
  const { data: paymentData } = useGetPayment({
    id: paymentId,
  });
  const { data: conversionData } = useGetConversion({
    id: conversionId,
  });

  useEffect(() => {
    const fetchInverseFxRate = async () => {
      if (conversionData?.data) {
        try {
          const inverseFxRate = await fetchFxRates({
            sellCurrency: conversionData.data.buyCurrency,
            buyCurrency: conversionData.data.sellCurrency,
            amount:
              conversionData.data.fixedSide === 'buy'
                ? conversionData.data.clientBuyAmount
                : conversionData.data.clientSellAmount,
            fixedSide: conversionData.data.fixedSide,
            conversionDate: DateTime.fromISO(
              conversionData.data.conversionDate,
            ).toISODate() as string,
          });
          setInverse(inverseFxRate.data);
        } catch (error) {
          console.error('Error fetching conversion rate:', error);
        }
      }
    };

    fetchInverseFxRate();
  }, [conversionData, fetchFxRates]);

  useEffect(() => {
    if (paymentData?.data) {
      setBeneficiaryId(paymentData?.data.beneficiaryId);
    }
  }, [paymentData]);

  const { data: beneficiaryData } = useGetBeneficiaryAccounts(
    beneficiaryId,
    true,
  );

  const transactionDetails = useMemo(() => {
    if (!transactionData) {
      return null;
    }

    return Object.keys(transactionDetailOrderMap)
      .map((key, value) => {
        if (key === 'amount') {
          return {
            key: t(`labels.${key}`),
            value: new Money({
              amount: +transactionData.data.amount,
              currency: transactionData.data.currency,
              locale,
            }).format(),
          };
        }

        if (
          key === 'createdAt' ||
          key === 'settledAt' ||
          key === 'completedAt'
        ) {
          if (!transactionData.data[key]) {
            return {
              key: t(`labels.${key}`),
              value: '',
            };
          }

          return {
            key: t(`labels.${key}`),
            value: new LocalDate({
              timestamp: transactionData.data[key],
              locale,
            }).format(),
          };
        }

        if (key === 'status') {
          return {
            key: t(`labels.${key}`),
            value: t(`values.status.${transactionData.data.status}`),
          };
        }

        if (key === 'action') {
          return {
            key: t(`labels.${key}`),
            value: t(`values.action.${transactionData.data.action}`),
          };
        }

        return {
          key: t(`labels.${key}`),
          value: (transactionData.data as any)[key],
        };
      })
      .filter((item) => item.value);
  }, [transactionData, locale, t]);

  const beneficiaryDetails = useMemo(() => {
    if (!beneficiaryData || beneficiaryData.data.length === 0) {
      return null;
    }
    const b = beneficiaryData.data[0];

    return Object.keys(beneficiaryDetailsOrderMap)
      .map((key) => {
        if (key === 'beneficiaryType') {
          return {
            key: t(`labels.${key}`, { ns: 'beneficiaries:details' }),
            value: t(`values.${b[key]}`, {
              ns: 'beneficiaries:details',
            }),
          };
        }

        if (key === 'beneficiaryAddress') {
          return {
            key: t(`labels.beneficiaryDetails.${key}`),
            value: `${(b[key] as unknown as string[]).join(',')}`,
          };
        }

        if (key === 'beneficiaryCountry') {
          return {
            key: t(`labels.beneficiaryDetails.${key}`),
            value: getCountryNameFromCode(b[key]),
          };
        }

        return {
          key: t(`labels.beneficiaryDetails.${key}`),
          value: (b as any)[key],
        };
      })
      .filter((item) => item.value);
  }, [beneficiaryData, t]);

  const conversionDetails = useMemo(() => {
    if (!conversionData || !inverse) {
      return null;
    }

    return Object.keys(conversionDetailsOrderMap).map((key) => {
      if (
        key === 'createdAt' ||
        key === 'settlementDate' ||
        key === 'conversionDate'
      ) {
        return {
          key: t(`labels.conversionDetails.${key}`),
          value: new LocalDate({
            timestamp: conversionData.data[key],
            locale,
          }).format(),
        };
      }

      if (key === 'status') {
        return {
          key: t(`labels.conversionDetails.${key}`),
          value: conversionData.data.status,
        };
      }

      if (key === 'fixedSide') {
        return {
          key: t(`labels.conversionDetails.${key}`),
          value:
            conversionData.data.fixedSide === 'buy'
              ? t(`labels.conversionDetails.buy`)
              : t(`labels.conversionDetails.sell`),
        };
      }

      if (key === 'buyCurrency') {
        return {
          key: t(`labels.conversionDetails.buy`),
          value: `${conversionData.data.clientBuyAmount} ${conversionData.data.buyCurrency}`,
        };
      }

      if (key === 'sellCurrency') {
        return {
          key: t(`labels.conversionDetails.sell`),
          value: `${conversionData.data.clientSellAmount} ${conversionData.data.sellCurrency}`,
        };
      }

      if (key === 'clientRate') {
        return {
          key: t(`labels.conversionDetails.${key}`),
          value: `${conversionData.data.clientRate} (${t('labels.conversionDetails.inverse')}: ${inverse.clientRate})`,
        };
      }

      return {
        key: t(`labels.conversionDetails.${key}`),
        value: (conversionData.data as any)[key],
      };
    });
  }, [conversionData, locale, t, inverse]);

  if (isFetching) {
    return (
      <PageWrapper className="flex flex-col gap-8">
        <Skeleton className="h-8 w-40 rounded-full" />
        <Skeleton className="h-8 w-80 rounded-full" />

        <Skeleton className="mt-16 h-8 w-80 rounded-full" />
        <div className="flex flex-col gap-2">
          <Skeleton className="h-8 w-96 rounded-full" />
          <Skeleton className="h-8 w-96 rounded-full" />
          <Skeleton className="h-8 w-96 rounded-full" />
          <Skeleton className="h-8 w-96 rounded-full" />
        </div>
      </PageWrapper>
    );
  }

  return (
    <PageWrapper className="flex flex-col">
      <Breadcrumb className="mb-10">
        <BreadcrumbList>
          <BreadcrumbItem>
            <BreadcrumbPage
              onClick={() => navigate({ to: '/transactions' })}
              className="cursor-pointer"
            >
              {t('title', { ns: 'transactions' })}
            </BreadcrumbPage>
          </BreadcrumbItem>
          <BreadcrumbSeparator />
          <BreadcrumbItem>
            <BreadcrumbPage>{id}</BreadcrumbPage>
          </BreadcrumbItem>
        </BreadcrumbList>
      </Breadcrumb>

      <header className="mb-10 flex items-center justify-between">
        <div className="flex items-center gap-2">
          <h1 className="text-3xl font-medium">Transaction (TODO)</h1>
          {transactionData?.data?.status && (
            <TransactionStatusBadge status={transactionData?.data?.status} />
          )}
        </div>

        {!isFetching && transactionData?.data?.status === 'pending' && (
          <div className="flex items-center gap-3">
            <Button variant="subtleDestructive">
              {t('actions.cancel', { ns: 'common' })}
            </Button>
          </div>
        )}
      </header>

      <section className="mb-10 w-1/2">
        <h3 className="mb-4 text-xl font-medium">{t('receipt.title')}</h3>

        {transactionDetails && <DetailsCard data={transactionDetails} />}
      </section>

      {beneficiaryData && (
        <section className="mb-10 w-1/2">
          <h3 className="mb-4 text-xl font-medium">
            {t(`beneficiaryDetails.title`, {
              ns: 'beneficiaries:details',
            })}
          </h3>
          <DetailsCard
            // @TODO: Bad approach, should be addressed in the future
            onClick={() => {
              navigate({
                to: '/beneficiaries/$id',
                params: { id: beneficiaryData.data[0].id },
              });
            }}
            data={[
              {
                key: t(`labels.beneficiaryDetails.name`),
                value: beneficiaryData.data[0].name,
              },
            ]}
          />
        </section>
      )}

      {beneficiaryDetails && (
        <section className="mb-10 w-1/2">
          <h3 className="mb-4 text-xl font-medium">
            {`${t(`beneficiaryDetails.title`, {
              ns: 'beneficiaries:details',
            })} -
                ${t(`bankAccountDetails.title`, {
                  ns: 'beneficiaries:details',
                })}`}
          </h3>
          <DetailsCard data={beneficiaryDetails} />
        </section>
      )}

      {conversionDetails && (
        <section className="mb-10 w-1/2">
          <h3 className="mb-4 text-xl font-medium">{t('conversion.title')}</h3>
          <DetailsCard data={conversionDetails} />
        </section>
      )}

      {paymentId && (
        <pre className="max-h-96 overflow-auto rounded bg-gray-100 p-4">
          {JSON.stringify(paymentTrackingData?.data, null, 2)}
        </pre>
      )}
    </PageWrapper>
  );
}

export default TransactionDetailsPage;
