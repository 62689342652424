import { Link } from '@tanstack/react-router';
import { useAuth0 } from '@auth0/auth0-react';
import { useTranslation } from 'react-i18next';
import {
  IconArrowLeftRight,
  IconArrowUpDown,
  IconArrowUpRight,
  IconCreditCard,
  IconInbox,
  IconLogout,
  IconPieChart,
  IconSettings,
  IconUpload,
  IconUsers,
  IconWallet,
  Logo,
} from '@agyt/client/shared/ui/icons';
import {
  BalanceCard,
  Button,
  NavigationItem,
  Skeleton,
} from '@agyt/client/shared/ui/components';
import i18next from 'i18next';
import { useBalances } from '@agyt/client/web/data-access/api';
import { Money } from '@agyt/shared/util/common';
import { useUser } from '@agyt/client/web/core/user';

const BalanceCardSkeleton = () => (
  <div className="mb-8 flex flex-col gap-1 rounded-md border border-slate-200 p-4 2xl:mb-[60px]">
    <Skeleton className="h-8 w-40 rounded-full" />
    <Skeleton className="h-5 w-32 rounded-full" />
    <Skeleton className="h-4 w-28 rounded-full" />
  </div>
);

export function Navigation() {
  const { t } = useTranslation('navigation');
  const { logout } = useAuth0();
  const { locale } = useUser();
  const { isPending, isError, data: balancesData, error } = useBalances();
  const data = balancesData?.data;
  const hasOverallData = data && data.overall;

  const getBalanceTotal = () => {
    if (hasOverallData) {
      return new Money({
        amount: data?.overall?.amount,
        currency: data?.overall?.currency,
        locale,
      }).format();
    }
    return '';
  };

  return (
    <div className="flex w-[296px] min-w-[296px] flex-col overflow-auto border-r border-slate-200 bg-white p-10">
      <div className="mb-8 2xl:mb-[100px]">
        <Link to="/">
          <Logo />
        </Link>
      </div>
      {isPending ? (
        <BalanceCardSkeleton />
      ) : isError ? (
        <p>{error.message}</p>
      ) : (
        hasOverallData && (
          <BalanceCard
            total={getBalanceTotal()}
            date={new Date().toLocaleDateString()}
            className="mb-8 2xl:mb-[60px]"
          />
        )
      )}
      <nav className="mb-8 text-slate-500 2xl:mb-[142px]">
        <ul>
          <li>
            <Link to="/" className="group">
              <NavigationItem
                title={t('labels.dashboard')}
                icon={<IconPieChart />}
              />
            </Link>
            <Link to="/account-currencies" className="group">
              <NavigationItem
                title={t('labels.currencies')}
                icon={<IconCreditCard />}
              />
            </Link>
            <Link to="/beneficiaries" className="group">
              <NavigationItem
                title={t('labels.beneficiaries')}
                icon={<IconUsers />}
              />
            </Link>
            <Link to="/pay" className="group">
              <NavigationItem
                title={t('labels.payments')}
                icon={<IconWallet />}
              />
            </Link>
            <Link to="/transactions" className="group">
              <NavigationItem
                title={t('labels.transactions')}
                icon={<IconArrowLeftRight />}
              />
            </Link>
            <Link to="/currency-exchange" className="group">
              <NavigationItem
                title={t('labels.fx')}
                icon={<IconArrowUpDown />}
              />
            </Link>
            <Link to="/welcome" className="group">
              <NavigationItem
                title={t('labels.accountDetails')}
                icon={<IconInbox />}
              />
            </Link>
          </li>
        </ul>
      </nav>
      <div className="flex justify-between">
        <Link to="/">
          <div className="flex flex-col items-center">
            <div className="flex h-[40px] w-[40px] items-center justify-center rounded-full bg-lime-200 text-lime-800 hover:bg-lime-300">
              <IconArrowLeftRight />
            </div>
            <div className="text-sm font-medium text-slate-500">
              {t('actions.exchange', { ns: 'common' })}
            </div>
          </div>
        </Link>
        <Link to="/">
          <div className="flex flex-col items-center">
            <div className="flex h-[40px] w-[40px] items-center justify-center rounded-full bg-lime-200 text-lime-800 hover:bg-lime-300">
              <IconArrowUpRight />
            </div>
            <div className="text-sm font-medium text-slate-500">
              {t('actions.pay', { ns: 'common' })}
            </div>
          </div>
        </Link>
        <Link to="/">
          <div className="flex flex-col items-center">
            <div className="flex h-[40px] w-[40px] items-center justify-center rounded-full bg-lime-200 text-lime-800 hover:bg-lime-300">
              <IconUpload />
            </div>
            <div className="text-sm font-medium text-slate-500">
              {t('actions.upload', { ns: 'common' })}
            </div>
          </div>
        </Link>
      </div>
      <div className="flex flex-1 flex-col justify-end">
        <Link to="/welcome" className="group">
          <NavigationItem
            title={t('labels.settings')}
            icon={<IconSettings />}
          />
        </Link>
        <Button
          variant="nav"
          size="nav"
          onClick={() =>
            logout({ logoutParams: { returnTo: window.location.origin } })
          }
        >
          <NavigationItem title={t('labels.logout')} icon={<IconLogout />} />
        </Button>
      </div>
    </div>
  );
}

export default Navigation;
