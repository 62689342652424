import { useTranslation } from 'react-i18next';
import { Button } from './button';

export function AccountListItem({
  name,
  balance,
  children,
  onPay,
  onExchange,
}: {
  name: string;
  balance: string;
  children: React.ReactNode;
  onPay: () => void;
  onExchange: () => void;
}) {
  const { t } = useTranslation('common');
  return (
    <div className="flex h-[77px] rounded-lg border border-slate-200 bg-white p-4">
      <div className="flex w-full items-center justify-between">
        <div className="flex items-center">
          <div className="mr-5">{children}</div>
          <div className="mr-10 text-base leading-7 text-slate-500">{name}</div>
          <div className="text-xl font-medium text-slate-900">{balance}</div>
        </div>

        <div className="flex w-[150px] gap-3">
          <Button variant="subtle" size="sm" onClick={onPay}>
            {t('actions.pay')}
          </Button>
          <Button variant="subtle" size="sm" onClick={onExchange}>
            {t('actions.exchange')}
          </Button>
        </div>
      </div>
    </div>
  );
}

export default AccountListItem;
