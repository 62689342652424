import {
  AccountCard,
  AccountListItem,
  Badge,
  Button,
  DataTable,
  GradientCircle,
  Label,
  PageWrapper,
  Switch,
  TabsTrigger,
  TransactionStatusBadge,
} from '@agyt/client/shared/ui/components';
import {
  IconArrowDownLeft,
  IconArrowLeftRight,
  IconArrowUpRight,
  IconLayoutGrid,
  IconList,
  IconPlusCircle,
} from '@agyt/client/shared/ui/icons';
import { useUser } from '@agyt/client/web/core/user';
import {
  useBalances,
  useFindTransactions,
} from '@agyt/client/web/data-access/api';
import { TransactionResponse } from '@agyt/shared/types';
import { Money } from '@agyt/shared/util/common';
import { Tabs, TabsContent, TabsList } from '@radix-ui/react-tabs';
import { Link, useNavigate } from '@tanstack/react-router';
import {
  ColumnDef,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface Transaction {
  id: string;
  balance_id: string;
  account_id: string;
  currency: string;
  amount: string;
  balance_amount: string;
  type: string;
  related_entity_type: string;
  related_entity_id: string;
  related_entity_short_reference: string;
  status: string;
  reason: string;
  settles_at: string;
  created_at: string;
  updated_at: string;
  completed_at: string;
  action: string;
}

function EmptyListView() {
  const { t } = useTranslation('accountCurrencies');
  return (
    <section className="flex h-[436px] w-full flex-col items-center justify-center rounded-lg border border-slate-200 bg-white p-4">
      <div className="flex max-w-min flex-col items-center justify-center">
        <h2 className="text-nowrap text-xl font-medium text-slate-900">
          {t('empty.title')}
        </h2>
        <p className="mt-2.5 text-center leading-7 text-slate-500">
          {t('empty.subtitle')}
        </p>
        <Link to="/account-currencies/new">
          <Button variant="subtle" className="mt-8">
            <IconPlusCircle />
            <span className="ml-2">{t('actions.add')}</span>
          </Button>
        </Link>
      </div>
    </section>
  );
}

export function AccountCurrenciesPage() {
  const { t } = useTranslation('accountCurrencies');
  const { locale } = useUser();
  const navigate = useNavigate({ from: '/account-currencies' });
  const [showAll, setShowAll] = useState(false);
  const {
    isLoading,
    data: balancesData,
    refetch: fetchBalances,
  } = useBalances(showAll);
  const { data: transactionsData, refetch: fetchTransactions } =
    useFindTransactions({
      orderAscDesc: 'desc',
    });
  const [transactions, setTransactions] = useState<TransactionResponse[]>([]);
  const accountCurrencies = balancesData?.data.balances;
  const colors = ['blue', 'violet', 'lime', 'teal', 'orange', 'slate'];

  const sortedCurrencies = accountCurrencies?.sort((a, b) => {
    if (a.amount === '0.00' && b.amount !== '0.00') {
      return 1;
    }
    if (a.amount !== '0.00' && b.amount === '0.00') {
      return -1;
    }
    return 0;
  });

  useEffect(() => {
    fetchBalances();
  }, [fetchBalances, showAll]);

  useEffect(() => {
    fetchTransactions();
  }, []);

  useEffect(() => {
    if (transactionsData) {
      setTransactions(transactionsData.data.slice(0, 3));
    }
  }, [transactionsData]);

  const columns: ColumnDef<TransactionResponse>[] = [
    {
      accessorKey: 'related_entity_type',
      header: 'Type',
      enableGlobalFilter: true,
      cell: ({ row: { original } }) => {
        const entityTypeMap: { [key: string]: string } = {
          inbound_funds: 'Inbound',
          payment: 'Outbound',
          conversion: 'Exchange',
          deposit: 'Deposit',
        };
        return (
          <div className="flex items-center gap-1">
            {original.relatedEntityType === 'inbound_funds' && (
              <IconArrowDownLeft />
            )}
            {original.relatedEntityType === 'payment' && <IconArrowUpRight />}
            {original.relatedEntityType === 'conversion' && (
              <IconArrowLeftRight />
            )}
            {entityTypeMap[original.relatedEntityType]}
          </div>
        );
      },
    },
    {
      accessorKey: 'amount',
      header: 'Amount',
      enableGlobalFilter: true,
      cell: ({ row: { original } }) => {
        const converted = Number(original.amount);
        const amount = new Money({
          amount: !isNaN(converted) ? converted : 0,
          currency: original.currency,
          locale,
        }).format();
        return <div>{amount}</div>;
      },
    },
    {
      accessorKey: 'currency',
      header: 'Currency',
      enableGlobalFilter: true,
    },
    {
      accessorKey: 'created_at',
      header: 'Date',
      enableGlobalFilter: true,
      cell: ({ row: { original } }) => (
        <div>{new Date(original.createdAt).toLocaleDateString()}</div>
      ),
    },
    {
      accessorKey: 'beneficiary_source',
      header: 'From',
      enableGlobalFilter: true,
    },
    {
      accessorKey: 'beneficiary_destination',
      header: 'To',
      enableGlobalFilter: true,
    },
    {
      accessorKey: 'id',
      header: 'Reference',
      enableGlobalFilter: true,
    },
    {
      accessorKey: 'payment_type',
      header: 'Payment Type',
      enableGlobalFilter: true,
    },
    {
      accessorKey: 'status',
      header: 'Status',
      enableGlobalFilter: true,
      cell: ({ row: { original } }) => (
        <TransactionStatusBadge status={original.status} />
      ),
    },
  ];

  const table = useReactTable({
    data: transactions || [],
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  if (isLoading) {
    return (
      <PageWrapper>
        <div>Loading...</div>
      </PageWrapper>
    );
  }

  return (
    <PageWrapper>
      <header className="flex items-center justify-between">
        <div className="flex items-center gap-2">
          <h1 className="text-3xl font-medium">{t('title')}</h1>
          <span>
            {!!sortedCurrencies?.length && (
              <Badge className="h-6 rounded-sm py-0.5">
                {sortedCurrencies.length}
              </Badge>
            )}
          </span>
        </div>
        <Link to="/account-currencies/new">
          <Button>
            <IconPlusCircle />
            <span className="ml-2">{t('actions.add')}</span>
          </Button>
        </Link>
      </header>
      <div className="mt-10">
        {sortedCurrencies?.length ? (
          <div>
            <div>
              <Tabs defaultValue="grid">
                <div className="flex w-full items-start justify-between">
                  <TabsList className="mb-6 w-[150px] rounded-sm bg-slate-200 p-1">
                    <TabsTrigger value="grid">
                      <IconLayoutGrid className="mr-1 h-4 w-4" />{' '}
                      {t('labels.grid')}
                    </TabsTrigger>
                    <TabsTrigger value="list">
                      <IconList className="mr-1 h-4 w-4" />
                      {t('labels.list')}
                    </TabsTrigger>
                  </TabsList>
                  <div className="flex items-center space-x-2">
                    <Switch
                      id="showAll"
                      onCheckedChange={() => setShowAll(!showAll)}
                    />
                    <Label htmlFor="airplane-mode">{t('labels.showAll')}</Label>
                  </div>
                </div>
                <TabsContent value="grid">
                  <section className="grid grid-cols-3 gap-6">
                    {sortedCurrencies.map((balance: any) => (
                      <AccountCard
                        key={balance.id}
                        name={`${balance.currency} ${t('labels.account')}`}
                        balance={new Money({
                          amount: balance.amount,
                          currency: balance.currency,
                          locale,
                        }).format()}
                        onPay={() => navigate({ to: '/pay' })}
                        onExchange={console.log}
                      >
                        <GradientCircle
                          variant={
                            colors[
                              Math.floor(Math.random() * colors.length)
                            ] as any
                          }
                        >
                          {balance.currency}
                        </GradientCircle>
                      </AccountCard>
                    ))}
                  </section>
                </TabsContent>

                <TabsContent value="list">
                  <section className="grid grid-cols-1 gap-2">
                    {sortedCurrencies.map((balance: any) => (
                      <AccountListItem
                        key={balance.id}
                        name={`${balance.currency} Account`}
                        balance={new Money({
                          amount: balance.amount,
                          currency: balance.currency,
                          locale,
                        }).format()}
                        onPay={() => navigate({ to: '/pay' })}
                        onExchange={console.log}
                      >
                        <GradientCircle
                          variant={
                            colors[
                              Math.floor(Math.random() * colors.length)
                            ] as any
                          }
                        >
                          {balance.currency}
                        </GradientCircle>
                      </AccountListItem>
                    ))}
                  </section>
                </TabsContent>
              </Tabs>
            </div>

            {transactionsData?.data.length && (
              <div className="mt-12">
                <div className="flex w-full justify-between">
                  <div className="text-xl font-medium text-slate-900">
                    {t('labels.latestTransactions')}
                  </div>
                  <Button
                    variant="ghost"
                    className="text-blue-500"
                    onClick={() => navigate({ to: '/transactions' })}
                  >
                    {t('actions.seeAll')}
                  </Button>
                </div>
                <DataTable<TransactionResponse>
                  table={table}
                  onRowClick={({ id }) => {
                    navigate({
                      to: '/transactions/$id',
                      params: { id },
                    });
                  }}
                />
              </div>
            )}
          </div>
        ) : (
          <EmptyListView />
        )}
      </div>
    </PageWrapper>
  );
}

export default AccountCurrenciesPage;
